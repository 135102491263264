import Home from "@/views/home.vue";
import Login from "@/views/login.vue";
import EsqueceuSenha from "@/views/esqueceu-senha.vue";
import CadastroPaciente from "@/views/cadastro_paciente.vue";
import CadastroPrimeiroPet from "@/views/cadastro_primeiro_pet.vue";
// import Pacientes from "@/views/pacientes.vue";
import PacientesTeste from "@/views/pacientes_teste.vue";
import Perfis from "@/views/perfis.vue";
import Unidades from "@/views/unidades.vue";
// import UnidadeRegiao from "@/views/unidade-regiao.vue";
import Filas from "@/views/listaFilas.vue";
import Assistentes from "@/views/assistentes.vue";
import Atendentes from "@/views/atendentes-recepcao.vue";
import Administrativos from "@/views/administrativos.vue";
// import Agendamento from "@/views/agendamentos.vue";
import Horarios from "@/views/horarios.vue";
import Procedimentos from "@/views/procedimentos";
import Racas from "@/views/racas";
import TipoProcedimento from "@/views/tipo_procedimentos";
import TipoEstorno from "@/views/tipo_estorno";
import Dashboard from "@/views/dashboard.vue";
import Recepcao from "@/views/recepcao.vue";
import DadosCadastrais from "@/views/dados_cadastrais.vue";
import TrocarSenha from "@/views/trocar_senha.vue";
import Prontuarios from "@/views/prontuarios.vue";
import MeuProntuario from "@/views/prontuario_paciente.vue";
import Formulario from "@/views/formularios.vue";
import Exames from "@/views/exames.vue";
import Medicamentos from "@/views/medicamentos.vue";
import Importacao from "@/views/importacao.vue";
import endpoints from "./endpoints";
import Pets from "@/views/pets.vue";
import Especies from "@/views/especies.vue";
import CentroCusto from "@/views/centro_custo.vue";
import FormaPagamento from "@/views/forma_pagamento.vue";
import Setor from "@/views/setor.vue";
import Medicos from "@/views/medicos.vue";
import AgendarConsulta from "@/views/agendar_consulta.vue";
import MinhasConsultas from "@/views/minhas_consultas.vue";
// import ClassificacaoRisco from "@/views/classificacao_risco.vue";
import Convenios from "@/views/convenios.vue";
import RecepcaoMedico from "@/views/recepcao_medico.vue";
import RelatorioComanda from "@/views/relatorio-comandas.vue";
import RelatorioItensExcluidos from "@/views/relatorio-itens-excluidos.vue";
import RelatorioProcedimentos from "@/views/relatorio-procedimentos.vue";
import MeusRecebimentos from "@/views/relatorio-recebimentos-recepcao.vue";
import Atendimentos from "@/views/relatorio-atendimentos.vue";
import PetsPorUnidade from "@/views/relatorio-pets-atendidos-unidade.vue";
import TutorPorUnidade from "@/views/relatorio-cadastro-tutor.vue";
import PetsUnidade from "@/views/relatorio-pets-unidade.vue";
import CaixaDiario from "@/views/relatorio-caixa-diario.vue";
// import RequisicoesConsulta from "@/views/requisicoes-consultas.vue";
// import RequisicoesExames from "@/views/requisicoes-exames.vue";
import FilaAtendimento from "@/views/fila_atendimentos.vue";
// import MinhasAgendas from "@/views/minhas_agendas.vue";
import ProcedimentoValor from "@/views/procedimento_valor.vue";
import ProcedimentoEspecialidade from "@/views/procedimento_especialidade.vue";
import Especialidade from "@/views/especialidade.vue";
import AgendamentoEspecialidade from "@/views/agendamento_especialidade.vue";
import PreAgendamento from "@/views/preagendamento.vue";
import MeusPets from "@/views/meus_pets.vue";
import AgendarTeleConulta from "@/views/agendar_teleconsulta.vue";
import TelemedicinaConsulta from "@/views/telemedicina_consulta.vue";
import DashboardCliente from "@/views/dashboard_cliente.vue";
import TelemedicinaDashboard from "@/views/dashboard_telemedicina.vue";
import TelemedicinaDisponibilidade from "@/views/disponibilidade_telemedicina.vue";
import TelemedicinaFila from "@/views/fila_telemedicina.vue";
import AgendamentoEspecialidadeRecepcao from "@/views/agendamento_especialidade_recepcionista.vue";
import Orcamento from "@/views/Orcamento.vue";
import Internacao from "@/views/internacao";
import AgendamentoCliente from "@/views/agendamento_cliente.vue";
import LegadoVetus from "@/views/legado_vetus.vue";
import Prefeituras from "@/views/prefeituras.vue";
import UsuarioPrefeitura from "@/views/usuario_prefeitura.vue";

// AS

const routes = [
  {
    path: "/telemedicina-principal",
    name: "telemedicina",
    component: TelemedicinaDashboard,
    icon: "dripicons-checklist",
    label: "Principal",
    menu: true,
    endpoints: [
      // endpoints["FilaEspecialidadeController::GET->getFilaClienteList"],
    ],
  },
  {
    path: "/fila-televet",
    name: "fila-televet",
    component: TelemedicinaFila,
    icon: "mdi-human-queue",
    label: "Fila TeleVet",
    menu: true,
    endpoints: [
      // endpoints["FilaEspecialidadeController::GET->getFilaClienteList"],
    ],
  },
  {
    path: "/telemedicina-disponibilidade",
    name: "telemedicina-diponibiliadade",
    component: TelemedicinaDisponibilidade,
    icon: "fas fa-lock",
    label: "Disponibilidade",
    menu: true,
    endpoints: [
      // endpoints["FilaEspecialidadeController::GET->getFilaClienteList"],
    ],
  },
  {
    path: "/telemedicina-consulta",
    name: "telemedicina-consulta",
    component: TelemedicinaConsulta,
    icon: "fas fa-notes-medical",
    label: "Atender TeleVet",
    menu: true,
    endpoints: [
      endpoints["FilaEspecialidadeController::GET->getFilaClienteList"],
    ],
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/dashboard-cliente",
    name: "dashboard_cliente",
    component: DashboardCliente,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/meus-pets",
    name: "meus_pets",
    component: MeusPets,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/agendar-teleconsulta",
    name: "agentar_teleconsulta",
    component: AgendarTeleConulta,
    icon: "dripicons-calendar",
    label: "Agendar Teleconsulta",
    menu: true,
    perfis: [4],
    endpoints: [],
  },
  {
    path: "/principal",
    name: "dashboard",
    component: Dashboard,
    icon: "dripicons-checklist",
    label: "Início",
    menu: true,
    endpoints: [
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
    ],
  },
  // {
  //   path: "/requisicoes-consultas",
  //   name: "requisicoes-consultas",
  //   component: RequisicoesConsulta,
  //   icon: "mdi-calendar-sync-outline",
  //   label: "Requisições de consulta",
  //   menu: true,
  //   endpoints: [
  //     endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
  //   ],
  // },
  // {
  //   path: "/requisicoes-exames",
  //   name: "requisicoes-exames",
  //   component: RequisicoesExames,
  //   icon: "mdi-calendar-sync-outline",
  //   label: "Requisições de exames",
  //   menu: true,
  //   endpoints: [endpoints["ExameController::GET->getExameList"]],
  // },
  // {
  //   path: "/atendentes",
  //   name: "atendentes",
  //   component: Atendentes,
  //   icon: "dripicons-user-group",
  //   label: "Atendentes - Recepção",
  //   menu: true,
  //   endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  // },
  {
    path: "/recepcao",
    name: "recepcao",
    component: Recepcao,
    icon: "dripicons-checklist",
    label: "Recepção",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/atendimentos",
    name: "atendimentos",
    component: Atendimentos,
    icon: "mdi-stethoscope",
    label: "Atendimentos",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/meus-recebimentos",
    name: "recebimentos",
    component: MeusRecebimentos,
    icon: "mdi-currency-usd",
    label: "Meus Recebimentos",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/caixa-diario",
    name: "caixa-diario",
    component: CaixaDiario,
    icon: "mdi-cash-register",
    label: "Caixa Diário",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/pet-unidade-atendidos",
    name: "pet-unidade",
    component: PetsPorUnidade,
    icon: "mdi-cat",
    label: "Pets Atendidos",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/tutor-unidade",
    name: "tutor-unidade",
    component: TutorPorUnidade,
    icon: "dripicons-user",
    label: "Tutor/Unidade",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/pets-unidade",
    name: "pets-unidade",
    component: PetsUnidade,
    icon: "mdi-paw",
    label: "Pets/Unidade",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/fila-atendimento",
    name: "fila_atendimento",
    component: FilaAtendimento,
    icon: "dripicons-checklist",
    label: "Fila de Atendimento",
    menu: true,
    endpoints: [
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/esqueceu-senha",
    name: "esqueci-minha-senha",
    component: EsqueceuSenha,
    icon: "",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/novo-cadastro",
    name: "cadastro-paciente",
    component: CadastroPaciente,
    icon: "dripicons-user",
    label: "Cadastro de Tutores",
    menu: false,
    endpoints: [],
  },
  {
    path: "/primeiro-pet",
    name: "primeiro-pet-tutor",
    component: CadastroPrimeiroPet,
    icon: "dripicons-user",
    label: "Cadastro de Pet",
    menu: false,
    endpoints: [],
  },
  {
    path: "/agendar_consulta",
    name: "agendar_consulta",
    component: AgendarConsulta,
    icon: "dripicons-user",
    label: "Agendar Consulta",
    menu: true,
    perfis: [4], // paciente
    endpoints: [],
  },
  // {
  //   path: "/minhas-agendas",
  //   name: "minhas_agendas",
  //   component: MinhasAgendas,
  //   icon: "dripicons-user",
  //   label: "Minhas Agendas",
  //   menu: true,
  //   endpoints: [endpoints["PacienteController::GET->getPacienteList"]],
  // },
  {
    path: "/minhas-consultas",
    name: "minhas_consultas",
    component: MinhasConsultas,
    icon: "dripicons-user",
    label: "Minhas Consultas",
    menu: true,
    endpoints: [],
    // endpoints: [endpoints["FilaEspecialidadeController::GET->getFilaClienteList"]]
  },
  // {
  //   path: "/tutores",
  //   name: "tutores",
  //   component: Pacientes,
  //   icon: "dripicons-user",
  //   label: "Tutores",
  //   menu: true,
  //   endpoints: [endpoints["PacienteController::GET->getPacienteList"]],
  // },
  {
    path: "/tutores",
    name: "tutores",
    component: PacientesTeste,
    icon: "dripicons-user",
    label: "Tutores",
    menu: true,
    endpoints: [endpoints["PacienteController::GET->getPacienteList"]],
  },
  {
    path: "/unidades",
    name: "unidades",
    component: Unidades,
    icon: "mdi-map-marker",
    label: "Unidades",
    menu: true,
    endpoints: [endpoints["UnidadeController::GET->getUnidadeList"]],
  },
  {
    path: "/prefeituras",
    name: "prefeituras",
    component: Prefeituras,
    icon: "mdi-home-city",
    label: "Prefeituras",
    menu: true,
    endpoints: [
      endpoints["AdministrativoController::GET->getAdministrativoList"],
    ],
  },
  // {
  //   path: "/unidade-regiao",
  //   name: "unidade-regiao",
  //   component: UnidadeRegiao,
  //   icon: "dripicons-user",
  //   label: "Unidade Região",
  //   menu: true,
  //   endpoints: [
  //     // endpoints["UnidadeController::GET->getUnidadeList"]
  //   ],
  // },
  {
    path: "/acompanhar-filas",
    name: "acompanhar-filas",
    component: Filas,
    icon: "dripicons-user-group",
    label: "Acompanhar Filas",
    menu: true,
    endpoints: [
      endpoints["ProcedimentoValorController::GET->getProcedimentoValorList"],
    ],
  },
  {
    path: "/agendamento_especialidade",
    name: "agendamento-especialidades",
    component: AgendamentoEspecialidade,
    icon: "dripicons-user-group",
    label: "Agenda Especialidade",
    menu: true,
    endpoints: [
      endpoints["AgendaEspecialidadeController::GET->getAgendaEspecialidade"],
    ],
  },
  {
    path: "/pre-agendamento",
    name: "pre-agendamento",
    component: PreAgendamento,
    icon: "dripicons-user-group",
    label: "Pré Agendamento",
    menu: true,
    endpoints: [
      endpoints["AgendaEspecialidadeController::GET->getAgendaEspecialidade"],
    ],
  },
  {
    path: "/centro-custo",
    name: "centro_custo",
    component: CentroCusto,
    icon: "dripicons-user",
    label: "Centro de Custo",
    menu: true,
    endpoints: [endpoints["CentroCustoController::GET->getCentroCustoList"]],
  },
  {
    path: "/forma-pagamento",
    name: "forma_pagamento",
    component: FormaPagamento,
    icon: "dripicons-user",
    label: "Formas de Pagamento",
    menu: true,
    endpoints: [
      endpoints["FormaPagamentoController::GET->getFormaPagamentoList"],
    ],
  },
  {
    path: "/setor",
    name: "setor",
    component: Setor,
    icon: "dripicons-user",
    label: "Setores",
    menu: true,
    endpoints: [endpoints["SetorController::GET->getSetorList"]],
  },
  {
    path: "/especialidade",
    name: "especialidade",
    component: Especialidade,
    icon: "dripicons-user",
    label: "Especialidades",
    menu: true,
    endpoints: [
      endpoints["EspecialidadeController::GET->getEspecialidadeList"],
    ],
  },
  {
    path: "/convenios",
    name: "convenios",
    component: Convenios,
    icon: "dripicons-user",
    label: "Convênios",
    menu: true,
    endpoints: [endpoints["ConvenioController::GET->getConvenioList"]],
  },
  {
    path: "/administrativos",
    name: "administrativos",
    component: Administrativos,
    icon: "dripicons-user-group",
    label: "Administrativos",
    menu: true,
    endpoints: [
      endpoints["AdministrativoController::GET->getAdministrativoList"],
    ],
  },
  {
    path: "/usuario-prefeitura",
    name: "usuario-prefeitura",
    component: UsuarioPrefeitura,
    icon: "dripicons-user-group",
    label: "Usuário Prefeitura",
    menu: true,
    endpoints: [
      endpoints["AdministrativoController::GET->getAdministrativoList"],
    ],
  },
  {
    path: "/assistentes",
    name: "assistentes",
    component: Assistentes,
    icon: "dripicons-user-group",
    label: "Assistentes",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/atendentes",
    name: "atendentes",
    component: Atendentes,
    icon: "dripicons-user-group",
    label: "Atendentes - Recepção",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/relatorio-comandas",
    name: "relatorio-comandas",
    component: RelatorioComanda,
    icon: "mdi-file-document-multiple",
    label: "Comandas",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/relatorio-itens-excluidos",
    name: "relatorio-itens-excluidos",
    component: RelatorioItensExcluidos,
    icon: "mdi-file-document-remove",
    label: "Itens Excluídos",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/relatorio-procedimentos",
    name: "relatorio-procedimentos",
    component: RelatorioProcedimentos,
    icon: "mdi-chart-pie",
    label: "Produtos / Serviços",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/prontuarios",
    name: "prontuarios",
    component: Prontuarios,
    icon: "fas fa-notes-medical",
    label: "Prontuários",
    menu: true,
    endpoints: [
      endpoints["ConsultaController::GET->getConsultaPacienteByMedicoID/$1"],
    ],
    props: { search: false },
  },
  {
    path: "/orcamento",
    name: "orcamento",
    component: Orcamento,
    icon: "mdi-calculator",
    label: "Orçamento",
    menu: true,
    endpoints: [
      endpoints["ConsultaController::GET->getConsultaPacienteByMedicoID/$1"],
    ],
  },
  // {
  //   path: "/agendamentos",
  //   name: "agendamentos",
  //   component: Agendamento,
  //   icon: "dripicons-calendar",
  //   label: "Agendamentos",
  //   menu: true,
  //   endpoints: [endpoints["AgendaController::GET->getAgenda"]],
  // },
  {
    path: "/horarios",
    name: "horarios",
    component: Horarios,
    icon: "dripicons-stopwatch",
    label: "Horários",
    menu: true,
    endpoints: [endpoints["AgendaController::GET->getAgenda"]],
  },
  {
    path: "/formularios",
    name: "formularios",
    component: Formulario,
    icon: "fas fa-file-medical-alt",
    label: "Formulários",
    menu: true,
    endpoints: [
      endpoints["FormularioController::GET->getFormulariosByMedicoID/$1"],
    ],
  },
  {
    path: "/procedimentos",
    name: "procedimentos",
    component: Procedimentos,
    icon: "dripicons-medical",
    label: "Procedimentos",
    menu: true,
    endpoints: [endpoints["ProcedimentoController::GET->getProcedimentoList"]],
  },
  {
    path: "/procedimentos-especialidade",
    name: "procedimentosEspecialidade",
    component: ProcedimentoEspecialidade,
    icon: "dripicons-medical",
    label: "Procedimento Especialidade",
    menu: true,
    endpoints: [endpoints["ProcedimentoController::GET->getProcedimentoList"]],
  },
  {
    path: "/tipos-procedimentos",
    name: "tipos-procedimentos",
    component: TipoProcedimento,
    icon: "dripicons-medical",
    label: "Tipos de Procedimentos",
    menu: true,
    endpoints: [
      endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList"],
    ],
  },
  {
    path: "/tipos-estorno",
    name: "tipos-estorno",
    component: TipoEstorno,
    icon: "mdi-cash-multiple",
    label: "Tipos de Estorno",
    menu: true,
    endpoints: [
      endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList"],
    ],
  },
  {
    path: "/procedimento-valor",
    name: "procedimentos-valor",
    component: ProcedimentoValor,
    icon: "dripicons-medical",
    label: "Procedimento Valor",
    menu: true,
    endpoints: [
      endpoints["ProcedimentoValorController::GET->getProcedimentoValorList"],
    ],
  },
  // {
  //   path: "/classificacao-risco",
  //   name: "classificacao-risco",
  //   component: ClassificacaoRisco,
  //   icon: "dripicons-medical",
  //   label: "Classificação de Risco",
  //   menu: true,
  //   endpoints: [
  //     endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList"],
  //   ],
  // },
  {
    path: "/exames",
    name: "exames",
    component: Exames,
    icon: "fas fa-vial",
    label: "Exames",
    menu: true,
    endpoints: [endpoints["ExameController::GET->getExameList"]],
  },
  {
    path: "/perfis",
    name: "perfis",
    component: Perfis,
    icon: "fas fa-vial",
    label: "Perfis",
    menu: true,
    endpoints: [endpoints["PerfilController::GET->getPerfilList"]],
  },
  {
    path: "/medicamentos",
    name: "medicamentos",
    component: Medicamentos,
    icon: "fas fa-medkit",
    label: "Medicamentos",
    menu: true,
    endpoints: [endpoints["MedicamentoController::GET->getMedicamentoList"]],
  },
  {
    path: "/dadoscadastrais",
    name: "dadoscadastrais",
    component: DadosCadastrais,
    icon: "",
    label: "Dados Cadastrais",
    menu: false,
    endpoints: [],
  },
  {
    path: "/trocarsenha",
    name: "trocarsenha",
    component: TrocarSenha,
    icon: "",
    label: "Trocar senha",
    menu: false,
    endpoints: [],
  },
  {
    path: "/filas-atendimento-medico",
    name: "filasatendimentomedico",
    component: RecepcaoMedico,
    icon: "dripicons-medical",
    label: "Filas de atendimento",
    menu: true,
    endpoints: [],
  },
  {
    path: "/meu-prontuario",
    name: "meu-prontuario",
    component: MeuProntuario,
    icon: "fas fa-file-medical-alt",
    label: "Meu prontuário",
    menu: true,
    endpoints: [[endpoints["PacienteController::GET->getMeuProntuario/$1"]]],
  },
  {
    path: "/medicos",
    name: "medicos",
    component: Medicos,
    icon: "fas fa-file-medical-alt",
    label: "Médicos",
    menu: true,
    endpoints: [endpoints["MedicoController::GET->getMedicoList"]],
  },
  {
    path: "/pets",
    name: "pets",
    component: Pets,
    icon: "fas fa-file-medical-alt",
    label: "Pets",
    menu: true,
    endpoints: [endpoints["PetController::GET->getPetList"]],
  },
  {
    path: "/internacao",
    name: "internacao",
    component: Internacao,
    icon: "mdi-hospital-building",
    label: "Internação",
    menu: true,
    endpoints: [endpoints["PetController::GET->getPetList"]],
  },
  {
    path: "/especies",
    name: "especies",
    component: Especies,
    icon: "fas fa-file-medical-alt",
    label: "Especies",
    menu: true,
    endpoints: [
      endpoints["EspecieController::GET->getEspecieList"],
      endpoints["EspecieController::GET->findAll"],
    ],
  },
  {
    path: "/racas",
    name: "racas",
    component: Racas,
    icon: "fas fa-file-medical-alt",
    label: "Raças",
    menu: true,
    endpoints: [endpoints["RacaController::GET->getRacaList"]],
  },
  {
    path: "/importacao",
    name: "importacao",
    component: Importacao,
    icon: "fas fa-file",
    label: "Importaçao",
    menu: true,
    endpoints: [
      endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList3"],
    ],
  },
  {
    path: "/agendamento-especialidade-recepcao",
    name: "agendamento_especialidade_recepcionista",
    component: AgendamentoEspecialidadeRecepcao,
    icon: "dripicons-calendar",
    label: "Agendamento Especialidade",
    menu: true,
    endpoints: [
      endpoints[
        "AgendaEspecialidadeController::GET->getAgendaEspecialidadeByUnidade/$1"
      ],
    ],
  },
  {
    path: "/agendamento-cliente",
    name: "agendamento_cliente",
    component: AgendamentoCliente,
    icon: "dripicons-calendar",
    label: "Agendamento Cliente",
    menu: true,
    endpoints: [
      // endpoints[
      //   "AgendaEspecialidadeController::GET->getAgendaEspecialidadeByUnidade/$1"
      // ],
    ],
  },
  {
    path: "/legado-vetus",
    name: "legado_vetus",
    component: LegadoVetus,
    icon: "mdi-text-box-search",
    label: "Legado Vetus",
    menu: true,
    endpoints: [
      // endpoints[
      //   "AgendaEspecialidadeController::GET->getAgendaEspecialidadeByUnidade/$1"
      // ],
    ],
  },
];

export default routes;
