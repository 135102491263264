const global = {
  api: {
    // Url Publico
    baseURL: "https://api.anclivepa-pb.pettree.com.br/v1/public",
    baseURLVetus: "https://api.anclivepa-pb.pettree.com.br/vet1/public",
    // baseURL: "https://api.televetbrasil.pettree.com.br/v1/public",
    baseUrlChamefacil_api: "https://chamefacilpettreeapi2.prolins.com.br/api",
    baseUrlChamefacil: "https://chamefacilpettree.prolins.com.br/api",

    // Homolog
    // baseURL: "https://pettreeapi.pb.homolog.prolins.com.br/v1/public",
    // baseURLVetus:
    //   "https://pettreeapi.pb.homolog.prolins.com.br/vet1/public/api",

    // Desenvolvimento
    // baseURL: "http://localhost/pettree_api/public",
    // baseURLVetus: "https://2a8e-54-233-91-190.ngrok-free.app/vet1/public/api",

    // baseURL: "https://pettreeapi.pb.homolog.prolins.com.br/v1/public",
    // baseURLVetus:
    //   "https://pettreeapi.pb.homolog.prolins.com.br/vet1/public/api",

    // baseURL: "https://1aa6-138-121-121-66.ngrok-free.app/anclivepa_base/public",
    // baseURLVetus:
    //   "https://5866-177-190-208-214.ngrok-free.app/anclivepa_legacy/api",
    // baseURL: "https://a9d5-201-20-66-137.ngrok-free.app/anclivepa_pb_a/public",
    // baseUrlChamefacil: "https://chamefacilpettreeteste.prolins.com.br/api",
    // baseUrlChamefacil_api: "https://chamefacilapiteste.prolins.com.br/api",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
