<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="pacientes"
      :expanded.sync="expanded"
      item-key="IdChamadaInterna"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item.DataCriacao="{ item }">
        <span>{{
          item.DataCriacao &&
            item.DataCriacao.split(" ")[0]
              .split("-")
              .reverse()
              .join("/")
        }}</span>
      </template>
      <template v-slot:item.HoraCriacao="{ item }">
        <span>{{ item.DataCriacao && item.DataCriacao.split(" ")[1] }}</span>
      </template>
      <template v-slot:item.tutor="{ item }">
        <span>{{ item.NomeTutor || item.UsuarioSenha }}</span>
      </template>
      <template v-slot:item.urgencia="{ item }">
        <span :class="urgenciaAtendimento(item.ClassificacaoAtendimento)">
          {{ item.ClassificacaoAtendimento }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="font-weight-bold text-left py-3 bg-white"
          :colspan="headers.length"
        >
          Observações:
          <b class="red--text font-weight-regular">{{
            item.ObservacaoExtra
          }}</b>
        </td>
      </template>
      <template v-slot:item.chamar="{ item }">
        <div class="d-flex justify-content-between">
          <v-btn
            small
            depressed
            color="success"
            @click="chamarSenha(item.IdChamadaInterna)"
          >
            Chamar
          </v-btn>
          <v-icon
            class="mr-2"
            color="primary"
            title="Prontuários"
            @click="listProntuarios(item)"
            >fas fa-file-medical-alt
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogProntuario"
      v-if="dialogProntuario"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <prontuarios
        :consultas="prontuarios"
        :pacienteInfo="pacienteInfo"
        @close="dialogProntuario = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";
import PetsService from "@/services/pets_service";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import Mixin from "@/mixins/vuex_mixin.js";
// import ModalAtender from "@/components/fila_atendimento/modal_atender";

export default {
  mixins: [Mixin],
  components: {
    Prontuarios,
  },
  data() {
    return {
      // pacienteEmAtendimento: {},
      // dialogAtender: false,
      tokenChamefacil: "",
      pacientes: [],
      listarFila: true,
      dialogObservacoes: false,
      headers: [
        {
          text: "Senha",
          align: "start",
          sortable: false,
          value: "Senha",
        },
        { text: "Tutor", value: "tutor" },
        { text: "Pet", value: "NomePet" },
        { text: "Id Pet", value: "PetCodigo" },
        { text: "Data de criação", value: "DataCriacao" },
        { text: "Horário de criação", value: "HoraCriacao" },
        { text: "Urgencia", value: "urgencia" },
        { text: "", value: "chamar" },
      ],
      petService: PetsService.build(),
      dialogProntuario: false,
      prontuarios: [],
      pacienteInfo: {},
    };
  },
  // components: { ModalAtender },
  beforeMount() {
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.listarFilaChamefacil();
    var fila = setInterval(() => {
      if (this.listarFila == false) {
        clearInterval(fila);
      } else {
        this.listarFilaChamefacil();
      }
    }, 5000);
  },
  destroyed() {
    this.listarFila = false;
  },
  // watch: {
  //   pacientes() {
  //     setTimeout(() => {
  //       this.listarFilaChamefacil();
  //     }, 15000);
  //   },
  // },
  methods: {
    listarFilaChamefacil() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/atendimento/listarsenhasinternaspendentesV2",
          { headers }
        )
        .then((resp) => {
          // console.log(resp.data.Senha);
          this.pacientes = resp.data.Senha;
        });
    },
    chamarSenha(idChamada) {
      this.$emit("chamarSenha", idChamada);
    },
    // atender(paciente) {
    //   if (!paciente.PetCodigo) {
    //     alert("O código do pet não está vinculado a esse atendimento. Verificar no chamefacil.")
    //   }
    //   this.pacienteEmAtendimento = paciente;
    //   this.dialogAtender = true;
    // },
    urgenciaAtendimento(urgencia) {
      return urgencia.toLowerCase() == "urgente"
        ? "red--text font-weight-bold"
        : "";
    },
    async listProntuarios(item) {
      console.log(item);
      await this.petService
        // .getConsultas(item.PetCodigo)
        .getConsultasbyUnidade(
          item.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          this.pacienteInfo = {
            nome: item.NomeTutor,
            nome_pet: item.NomePet,
            id_pet: item.PetCodigo,
            id_cliente: item.TutorCodigo,
          };
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.dialogProntuario = true;
        });
    },
  },
};
</script>

<style scoped>
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 0px;
}
</style>
